import React from "react"
import styled from "styled-components"
import AccordionItem from "../Accordion/AccordionItem"

import { GreyBackground, GreyRequirements, SmallTitle } from "../../styles/sharedStyles";

import breakpoints from "../../styles/breakpoints"
import ApplyNowDrawer from "../ApplyNowDrawer"
import { LinkUnderline } from "../NormalLink"
import BlockText from "../BlockText"
import localeData from "../../locale"

const Container = styled.div`
  display: flex;
  ${breakpoints.md`
    flex-direction: ${props =>
      props.MirrorContentDesktop ? "row-reverse" : "initial"};
  `};
`

const FinalContainer = styled.div`
  display: flex;

  ${breakpoints.md`
    display: block;
  `};
`

const Step = styled.div`
  width: 5%;
  position: relative;
  flex-grow: 1;
  border-left: 2px dashed ${props => props.theme.colors.black200};
  margin-top: 24px;
  margin-left: 23px;
  margin-right: 23px;
  ${breakpoints.md`
    transform: translateX(50%);
  `};
`

const FinalStep = styled(Step)`
  border-left: 0;
  margin-left: 24px;
  margin-right: 24px;
  ${breakpoints.md`
    left: 50%;
    transform: translateX(-50%);
  `};
`

const ProcessIcon = styled.div`
  position: absolute;
  top: -24px;
  left: 50%;
  transform: translateX(-50%);
  img {
    height: 48px;
    width: 48px;
  }
  ${breakpoints.md`
    img {
      height: 88px;
      width: 88px;
    }
    left: initial;
  `};
`

const StepLabel = styled.strong`
  color: ${props => props.theme.colors.black900};
  ${breakpoints.md`
    font-size: 20px;
  `};
`

const Content = styled.div`
  flex-grow: 2;
  margin-left: 16px;
  ${breakpoints.md`
    margin-left: ${props => (props.MirrorContentDesktop ? "0" : "16px")};
    margin-right: ${props => (props.MirrorContentDesktop ? "16px" : "0")};
    width: 45%;
  `};
`

const FinalContent = styled(Content)`
  ${breakpoints.md`
    width: 100%;
    text-align: center;
    margin: 0;
    div {
      padding-top: 100px;
        p {
          max-width: 50%;
          margin: 0 auto;
        }
    }
  `};
`

const EmptyBlock = styled.div`
  display: none;
  ${breakpoints.md`
    margin-right: 14px;
    flex-grow: 2;
    width: 45%;
    display: block;
    flex-direction: ${props =>
      props.MirrorContentDesktop ? "row-reverse" : "initial"};
  `};
`

const StepSpacer = styled.div`
  border-left: 2px dashed ${props => props.theme.colors.black200};
  margin-top: 2px;
  margin-left: 23px;
  margin-right: 23px;
  height: 40px;
  ${breakpoints.md`
    width: 2px;
    display: block;
    transform: translateX(-50%);
    margin-left: ${props =>
      props.MirrorContentDesktop ? "calc(50% + 2px)" : "50%"};
  `};
`

const CallToAction = styled.div`
  text-align: center;
  padding: 48px 0;
  button {
    margin-bottom: 24px;
    ${breakpoints.sm`
      margin-bottom: 0;
      margin-right: 48px;
  `};
  }
`

const StepsProcess = ({ data, locale, cta }) => (
  <div>
    {data.map((step, index, arr) => {
        // Final step
        if (arr.length - 1 === index) {
          return (
            <React.Fragment key={step._key}>
              <FinalContainer key={step._key}>
                <FinalStep>
                  <ProcessIcon>
                    {step.icon && (
                      <img
                        src={`${step.icon.asset.url}?w=112`}
                        alt={step.stepTitle[locale] || "Step"}
                      />
                    )}
                  </ProcessIcon>
                </FinalStep>
                <FinalContent>
                  <div>
                    {step.stepTitle && (
                      <StepLabel>{step.stepTitle[locale]}</StepLabel>
                    )}
                    {step.stepTextBlocks && (
                      <BlockText blocks={step.stepTextBlocks[locale]} />
                    )}
                  </div>
                </FinalContent>
              </FinalContainer>
              <CallToAction>
                <ApplyNowDrawer locale={locale} btn="Primary" />
                <LinkUnderline to={cta}>
                  {localeData._rawExploreVacancyBtn[locale]}
                </LinkUnderline>
              </CallToAction>
            </React.Fragment>
          )
        }

        // Even Steps
        if (index % 2 === 0) {
          return (
            <React.Fragment key={step._key}>
              <Container MirrorContentDesktop>
                <EmptyBlock />
                <Step>
                  <ProcessIcon>
                    {step.icon && (
                      <img
                        src={`${step.icon.asset.url}?w=112`}
                        alt={step.stepTitle[locale] || "Step"}
                      />
                    )}
                  </ProcessIcon>
                </Step>
                <Content MirrorContentDesktop>
                  {step.stepTitle && (
                    <StepLabel>{step.stepTitle[locale]}</StepLabel>
                  )}
                  {step.stepTextBlocks && (
                    <BlockText blocks={step.stepTextBlocks[locale]} />
                  )}
                  {step.specialRequirements &&
                  step.specialRequirements.length ? (
                    <GreyBackground>
                      <GreyRequirements>
                        {step.specialRequirementsHeader && (
                          <SmallTitle>
                            {step.specialRequirementsHeader[locale]}
                          </SmallTitle>
                        )}
                        {step.specialRequirements && (
                          <ul>
                            {step.specialRequirements.map(item => (
                              <li key={item._key}>{item[locale]}</li>
                            ))}
                          </ul>
                        )}
                      </GreyRequirements>
                    </GreyBackground>
                  ) : null}
                  {step.faqs && step.faqs.length
                    ? step.faqs.map(faq => (
                      <AccordionItem
                        key={faq._key}
                        header={faq.question[locale]}
                        body={faq.answer}
                        locale={locale}
                      />
                      ))
                    : null}
                </Content>
              </Container>
              <StepSpacer MirrorContentDesktop />
            </React.Fragment>
          )
        }

        // Odd Steps
        return (
          <React.Fragment key={step._key}>
            <Container key={step._key}>
              <EmptyBlock />
              <Step>
                <ProcessIcon>
                  {step.icon && (
                    <img
                      src={`${step.icon.asset.url}?w=112`}
                      alt={step.stepTitle[locale] || "Step"}
                    />
                  )}
                </ProcessIcon>
              </Step>
              <Content>
                {step.stepTitle && (
                  <StepLabel>{step.stepTitle[locale]}</StepLabel>
                )}
                {step.stepTextBlocks && (
                  <BlockText blocks={step.stepTextBlocks[locale]} />
                )}
                {step.specialRequirements && step.specialRequirements.length ? (
                  <GreyBackground>
                    <GreyRequirements>
                      {step.specialRequirementsHeader && (
                        <SmallTitle>
                          {step.specialRequirementsHeader[locale]}
                        </SmallTitle>
                      )}
                      {step.specialRequirements && (
                        <ul>
                          {step.specialRequirements.map(item => (
                            <li key={item._key}>{item[locale]}</li>
                          ))}
                        </ul>
                      )}
                    </GreyRequirements>
                  </GreyBackground>
                ) : null}
                {step.faqs && step.faqs.length
                  ? step.faqs.map(faq => (
                    <AccordionItem
                      key={faq._key}
                      header={faq.question[locale]}
                      body={faq.answer}
                      locale={locale}
                    />
                    ))
                  : null}
              </Content>
            </Container>

            <StepSpacer />
          </React.Fragment>
        )
      })}
  </div>
  )

export default StepsProcess
