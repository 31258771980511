import React from "react"
import { graphql } from "gatsby"
import Grid from "@material-ui/core/Grid"

import SEO from "../components/seo"
import HeroFullWidth from "../components/HeroFullWidth"
import Section from "../components/Section"
import SvgBorderTop from "../components/SvgBorderTop"
import { Container } from "../components/Container"
import BlockText from "../components/BlockText"
import FaqCategory from "../components/FaqCategory"
import BreadCrumbs from "../components/BreadCrumbs"
import LetsConnect from "../components/LetsConnect"
import StepsProcess from "../components/StepsProcess"
import IntroBlockStyle from "../components/IntroBlockStyle"
import { currentLocale } from "../locale/languages"
import slugify from "../utils/slugify"

export const query = graphql`
  {
    sanityOurProcessPage {
      id
      _rawLocaleTitle
      _rawHeroTitle
      _rawTextBlock1
      image {
        asset {
          gatsbyImageData(width: 1600, fit: FILLMAX, placeholder: BLURRED)
        }
      }
      _rawAllSteps(resolveReferences: { maxDepth: 3 })
      faqCategory {
        _rawFaqs
      }
      teamMember {
        name
        degree
        phone
        email
        image {
          asset {
            url
          }
        }
      }
    }
    sanityAllVacanciesPage {
      id
      _rawLocaleTitle
    }
  }
`

const OurProcess = ({ data, pageContext }) => {
  const { locale } = pageContext
  const {
    image,
    _rawLocaleTitle,
    _rawHeroTitle,
    _rawTextBlock1,
    _rawAllSteps,
    faqCategory,
    teamMember,
  } = data.sanityOurProcessPage

  const { sanityAllVacanciesPage } = data
  const setLocaleInUrl = currentLocale !== locale ? `${locale}/` : ""

  return (
    <>
      <SEO title={_rawLocaleTitle[locale]} />
      {_rawLocaleTitle && (
        <BreadCrumbs
          color="white"
          pageTitle={_rawLocaleTitle[locale]}
          locale={locale}
        />
      )}
      {image && (
        <HeroFullWidth img={image} title={_rawHeroTitle[locale]} />
      )}
      {_rawTextBlock1 && (
        <Section paddingTop paddingBottom>
          <Container>
            <Grid container justify="center">
              <Grid item xs={12} md={8}>
                <IntroBlockStyle>
                  <BlockText blocks={_rawTextBlock1[locale]} />
                </IntroBlockStyle>
              </Grid>
            </Grid>
          </Container>
        </Section>
      )}
      {_rawAllSteps && (
        <Container paddingBottom>
          <Section>
            <StepsProcess
              data={_rawAllSteps}
              locale={locale}
              cta={`/${setLocaleInUrl}${slugify(
                sanityAllVacanciesPage._rawLocaleTitle[locale]
              )}`}
            />
          </Section>
        </Container>
      )}
      {faqCategory && (
        <SvgBorderTop>
          <Container paddingBottom>
            <Section>
              <FaqCategory category={faqCategory} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
      {teamMember && (
        <SvgBorderTop grey>
          <Container grey paddingBottom>
            <Section>
              <LetsConnect data={teamMember} locale={locale} />
            </Section>
          </Container>
        </SvgBorderTop>
      )}
    </>
  )
}

export default OurProcess
